import GATSBY_COMPILED_MDX from "/opt/build/repo/portfolio/contests/index.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/layout';
import Seo from '../components/seo';
const BlogPost = ({data, children}) => {
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("div", {
    className: "spacer"
  }), React.createElement(Container, null, React.createElement(Row, null, React.createElement(Col, {
    md: 1
  }), React.createElement(Col, {
    md: 10
  }, children), React.createElement(Col, {
    md: 1
  }))));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        featured_image_alt
        featured_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
